var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{directives:[{name:"editable",rawName:"v-editable",value:(_vm.blok),expression:"blok"},{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.aosVisibilityChanged,
    once: true,
    throttle: 150,
    intersection: {
      rootMargin: '-86px 0px',
      threshold: 0,
    },
  }),expression:"{\n    callback: aosVisibilityChanged,\n    once: true,\n    throttle: 150,\n    intersection: {\n      rootMargin: '-86px 0px',\n      threshold: 0,\n    },\n  }"}],staticClass:"how-buy",class:{
    'swiper-pagination-stop': !_vm.isVisibleSwiper,
  },attrs:{"id":_vm.blok.id || null}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"how-buy__wr -g-d-f -g-ai-c -g-jc-sb"},[_c('div',{staticClass:"how-buy__left"},[(_vm.blok.title)?_c(_vm.heading,{tag:"component",staticClass:"how-buy__title -h2 -ws-pl",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"},domProps:{"innerHTML":_vm._s(_vm.blok.title)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"how-buy__left-list hide-mob"},_vm._l((_vm.slides),function(slide,idx){return _c('UiHowBuySlide',{key:slide._uid,class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up","data-aos-delay":(150 * idx) + 150,"blok":slide,"parentIndex":_vm.index,"index":idx,"active":slide._uid === _vm.activeSlide,"clickable":_vm.slides.length > 1},on:{"click":function($event){return _vm.slideTo($event)}}})}),1)],1),_vm._v(" "),_c('div',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
          callback: _vm.swiperVisibilityChanged,
          intersection: {
            rootMargin: '-86px 0px',
          },
        }),expression:"{\n          callback: swiperVisibilityChanged,\n          intersection: {\n            rootMargin: '-86px 0px',\n          },\n        }"}],staticClass:"how-buy__img",class:[_vm.aosAnimate],attrs:{"data-aos":"fade-up"}},[_c('UiHowToBuySwiper',{ref:"refSwiperImg",attrs:{"slides":_vm.slides,"isVisibleSwiper":_vm.isVisibleSwiper},on:{"slideChange":_vm.slideChange}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }