//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiHowBuySlide',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    parentIndex: {
      type: Number,
      default: null,
    },
    index: {
      type: Number,
      default: null,
    },
    active: {
      type: Boolean,
      default: false,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    heading() {
      switch(this.parentIndex) {
        case 0:
          return 'h2';
        default:
          return 'h3';
      }
    },
  },
  methods: {
    handleClick() {
      if (!this.clickable) return;
      this.$emit('click', this.index);
    }
  },
}
