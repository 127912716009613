//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'UiHowToBuySwiper',
  props: {
    parentIndex: {
      type: Number,
      default: null,
    },
    slides: {
      type: Array,
      default: () => [],
    },
    isVisibleSwiper: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      imgOptions: {
        slidesPerView: 1,
        watchOverflow: true,
        autoHeight: true,
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
      },
    }
  },
  computed: {
    swiperImg() {
      return this.$refs.refSwiperImg.$swiper;
    },
  },
  methods: {
    slideChange() {
      const realIndex = this.swiperImg.realIndex;

      this.$emit('slideChange', {realIndex});
    },
    slideTo(index) {
      this.swiperImg.slideTo(index);
      setTimeout(() => {
        this.handleSwiperStop();
      }, 0);
    },
    onImgLoad(e) {
      this.swiperImg.updateAutoHeight();
    },
    autoplayStart() {
      this.$el.classList.remove('swiper-pagination-pause');
    },
    autoplayStop() {
      this.$el.classList.add('swiper-pagination-pause');
    },
    handleSwiperStop() {
      this.swiperImg.autoplay.stop();
    },
    handleSwiperStart() {
      if (this.isVisibleSwiper) {
        this.swiperImg.autoplay.start();
      }
    },
  },
  mounted() {
    this.swiperImg
      .on('autoplayStart', () => {
        this.autoplayStart();
      })
      .on('autoplayStop', () => {
        this.autoplayStop();
      });
  },
}
