//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'BlokHomeHowToBuy',
  props: {
    blok: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isVisibleAos: false,
      isVisibleSwiper: false,
      realIndex: 0,
    }
  },
  computed: {
    aosAnimate() {
      return this.isVisibleAos ? 'aos-animate' : null;
    },
    heading() {
      switch(this.index) {
        case 0:
          return 'h1';
        default:
          return 'h2';
      }
    },
    slides() {
      return this.blok.slides || [];
    },
    activeSlide() {
      return this.slides[this.realIndex]?._uid;
    },
  },
  methods: {
    slideChange(params) {
      this.realIndex = params.realIndex;
    },
    slideTo(index) {
      this.$refs.refSwiperImg.slideTo(index);
    },
    aosVisibilityChanged(isVisible) {
      this.isVisibleAos = isVisible;
    },
    swiperVisibilityChanged(isVisible) {
      this.isVisibleSwiper = isVisible;

      if (isVisible) {
        this.$refs.refSwiperImg.swiperImg.autoplay.start();
      } else {
        this.$refs.refSwiperImg.swiperImg.autoplay.stop();
      }
    },
  },
}
