import { render, staticRenderFns } from "./HowToBuy.vue?vue&type=template&id=47846fd8&scoped=true&"
import script from "./HowToBuy.vue?vue&type=script&lang=js&"
export * from "./HowToBuy.vue?vue&type=script&lang=js&"
import style0 from "./HowToBuy.vue?vue&type=style&index=0&id=47846fd8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47846fd8",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiHowBuySlide: require('/app/components/storyblok/ui/HowBuySlide.vue').default,UiHowToBuySwiper: require('/app/components/ui/HowToBuySwiper.vue').default})
