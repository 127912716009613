import { render, staticRenderFns } from "./HowToBuySwiper.vue?vue&type=template&id=91a76cbc&scoped=true&"
import script from "./HowToBuySwiper.vue?vue&type=script&lang=js&"
export * from "./HowToBuySwiper.vue?vue&type=script&lang=js&"
import style0 from "./HowToBuySwiper.vue?vue&type=style&index=0&id=91a76cbc&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91a76cbc",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiHowBuySlide: require('/app/components/storyblok/ui/HowBuySlide.vue').default})
